import logo from './dishLogo.png';
import './App.css';

function App() {
  return (
    <div className="container">
      <main>
        <img src={logo} className="App-logo" alt="Dish Logo" style={{ borderRadius: "10px", overflow: "hidden"}} width="50" height="50" />
        <h1 className="App-title">Dish Reviews</h1>
        <a
          className="App-link"
          href="mailto: cobygoldstein@gmail.com?subject=Dish Reviews Contact Us"
        >
          Contact us
        </a>
      </main>
      <footer>
        <div>
          <a
            className="App-link"
            href="https://docs.google.com/document/d/1v_50duEbTfPmQS2bEPn_PzlDMObTIT4RiUhPy1LIURE/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a
            className="App-link"
            href="https://docs.google.com/document/d/1O3Funr5b7mAdAavpc0NvshJHdeRi3W6TD5zk1Cn_MdA/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
